import {
  Breakpoints,
  Button,
  ButtonColor,
  IconArrowRound,
  IconBarChart,
  IconMoneyChat,
  IconPackage,
  IconShare,
  Modal,
  ModalContent,
  ModalHeight,
  type ModalHook,
  ModalSize,
  useControlledModalState,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import React, { type FC, useCallback } from 'react';

import newCoachExperienceModalImage from '../../assets/images/new_exp_interaction_lg.png';
import newCoachExperienceModalImageMobile from '../../assets/images/new_exp_interaction_sm_md.png';

interface NewCoachExperienceModalHookProps {
  onClose?: () => void;
}

interface NewCoachExperienceModalProps
  extends NewCoachExperienceModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const NewCoachExperienceModalContent: FC<NewCoachExperienceModalProps> = ({
  onClose,
  open,
  setOpen: setOpenProp,
}) => {
  const setOpen = useCallback(
    (open: boolean) => {
      setOpenProp(open);
      if (!open && onClose) onClose();
    },
    [onClose, setOpenProp],
  );

  const features = [
    {
      Icon: IconMoneyChat,
      title: 'More Leland-sourced coaching opportunities',
      description:
        'Standardized pricing & offerings enables Leland to send you clients who have already purchased. Spend less time selling, more time coaching.',
    },
    {
      Icon: IconBarChart,
      title: 'Coach pricing tiers',
      description:
        'Coaches are categorized into pricing tiers based on their coaching experience. Tiers make it easier for customers in your price range to find you. Your hourly rate and custom package pricing are now determined by your tier.',
    },
    {
      Icon: IconArrowRound,
      title: 'Coaching subscriptions',
      description:
        'You can now sell coaching subscriptions to clients, with time balances that automatically renew every month.',
    },
    {
      Icon: IconPackage,
      title: 'Standardized packages',
      description:
        'Now you can offer Leland-created packages on your profile. These standardized packages target clients’ most common needs and are easier for clients to understand and choose between.',
    },
    {
      Icon: IconShare,
      title: 'Referrals',
      description:
        'When you refer clients to Leland, we reduce our platform fee to 5% when they work with you and pay you 5% of everything they spend with other coaches.',
    },
  ];

  const isSm = useMediaQuery(Breakpoints.SM);
  const isLg = useMediaQuery(Breakpoints.LG);

  const header = isSm ? null : 'New for Coaches';

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        useFullSpace
        height={ModalHeight.MEDIUM}
        size={isLg ? ModalSize.LARGE : ModalSize.MEDIUM}
        header={header}
      >
        <div className="flex h-full flex-col gap-4 lg:flex-row">
          <Image
            src={newCoachExperienceModalImage}
            alt="New coach experience modal"
            className="hidden object-contain lg:block"
          />
          <Image
            src={newCoachExperienceModalImageMobile}
            alt="New coach experience modal"
            className="block lg:hidden"
          />
          <div className="flex flex-col gap-2 space-y-4 p-5 sm:p-10 lg:overflow-y-auto">
            <div className="space-y-5 text-center sm:space-y-2 sm:text-left">
              <h1 className="px-8 text-3xl font-medium sm:px-0">
                Coaching on Leland just got better.
              </h1>
              <p className="text-sm text-gray-500">
                Leland is launching a bunch of changes to the coaching ecosystem
                that will affect your coaching business, all with the purpose of
                bringing you more business.
              </p>
            </div>
            <hr className="border-gray-200" />
            {features.map(({ title, description, Icon }) => (
              <div className="flex gap-4" key={title}>
                <Icon className="size-6 shrink-0" />
                <div>
                  <h2 className="text-lg font-medium">{title}</h2>
                  <p className="text-sm text-gray-500">{description}</p>
                </div>
              </div>
            ))}
            <hr className="border-gray-200" />
            <Button
              label={'Check it out'}
              buttonColor={ButtonColor.PRIMARY}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export const useNewCoachExperienceModal: ModalHook<
  NewCoachExperienceModalHookProps
> = () => {
  const [open, setOpen] = useControlledModalState();
  const NewCoachExperienceModalComponent: FC<
    NewCoachExperienceModalHookProps
  > = (props) => {
    return open ? (
      <NewCoachExperienceModalContent
        {...props}
        open={open}
        setOpen={setOpen}
      />
    ) : null;
  };
  return { open, setOpen, Modal: NewCoachExperienceModalComponent };
};
