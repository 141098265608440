import { formatPrice } from '@leland-dev/leland-ui-library';

import {
  CareerGoalTarget,
  CoachingRelationshipStatus,
  type CoachStatus,
  CouponPricingType,
  CouponType,
  ExperienceType,
  GoalName,
  type OrderStatus,
  PreferredPronoun,
  type ReviewStatus,
  SubscriptionInterval,
} from '../__generated-gql-types__/globalTypes';

import {
  ALL_SERVICES_COUPON_TYPE,
  CoachingRelationshipFlag,
} from './constants';
import { assertUnreachable } from './typescript';

const COUPON_PRICING_TYPE_LABEL: Record<CouponPricingType, string> = {
  [CouponPricingType.HOURLY]: 'Hourly Rates',
  [CouponPricingType.PACKAGE]: 'Package Offerings',
  [CouponPricingType.CLASS]: 'Group Classes',
  [CouponPricingType.BOOTCAMP]: 'Bootcamps',
};

const COACHING_STATUS_LABELS: Record<CoachingRelationshipStatus, string> = {
  [CoachingRelationshipStatus.ACTIVE]: 'Active Client',
  [CoachingRelationshipStatus.CONFIRMED_LEAD]: 'Lead',
  [CoachingRelationshipStatus.LEAD]: 'Lead',
  [CoachingRelationshipStatus.CLASS_ATTENDEE]: 'Class Attendee',
};

const COACHING_FLAG_LABELS: Record<CoachingRelationshipFlag, string> = {
  [CoachingRelationshipFlag.ARCHIVED]: 'Archived Client',
  [CoachingRelationshipFlag.BLOCKED]: 'Blocked Client',
  [CoachingRelationshipFlag.PENDING_ORDER]: 'Pending Order',
  [CoachingRelationshipFlag.PENDING_INTRO_REQUEST]: 'Pending Intro Request',
  [CoachingRelationshipFlag.PENDING_INTRO_MESSAGE]: 'New Conversation',
};

const GOAL_NAME_LABELS: Record<GoalName, string> = {
  [GoalName.CAREER]: 'Build your Career',
  [GoalName.SCHOOL]: 'Get into School',
  [GoalName.TEST]: 'Take a Test',
};

const EXPERIENCE_TYPE_LABELS: Record<ExperienceType, string> = {
  [ExperienceType.ADMISSIONS_COMMITTEE]: 'Admissions Committee Experience',
  [ExperienceType.HIRING_MANAGER]: 'Hiring Manager',
};

const CAREER_GOAL_TARGET_LABELS: Record<CareerGoalTarget, string> =
  Object.freeze({
    [CareerGoalTarget.INTERNSHIP]: 'Get an internship',
    [CareerGoalTarget.FULL_TIME_JOB]: 'Land first full-time job',
    [CareerGoalTarget.PIVOT]: 'Pivot careers',
    [CareerGoalTarget.CHANGE_COMPANIES]: 'Change company',
    [CareerGoalTarget.LEVEL_UP]: 'Level up your career',
    [CareerGoalTarget.OTHER]: 'Other',
  });

const PREFERRED_PRONOUN_LABELS: Record<PreferredPronoun, string> =
  Object.freeze({
    [PreferredPronoun.HE_HIM]: 'He/Him',
    [PreferredPronoun.SHE_HER]: 'She/Her',
    [PreferredPronoun.THEY_THEM]: 'They/Them',
    [PreferredPronoun.DO_NOT_SPECIFY]: 'Do not specify',
  });

const CAPITALIZE_EXECEPTIONS = {
  gmat: 'GMAT',
  gre: 'GRE',
  i: 'I',
  and: '&',
  or: 'or',
};

/**
 * Format string to upper case the first letter and lower case the remaining characters
 * @param {string} string
 * @returns capitalized string, like "Diamond hands"
 */
export const capitalize = (string: string): string => {
  const lowerCase = string.toLowerCase();
  if (lowerCase in CAPITALIZE_EXECEPTIONS) {
    return CAPITALIZE_EXECEPTIONS[
      lowerCase as keyof typeof CAPITALIZE_EXECEPTIONS
    ];
  }

  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
};

const createImageFromText = (
  size: number,
  text: string,
  backgroundColor: string,
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = canvas.height = size;

  if (context) {
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${backgroundColor}50`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = backgroundColor;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${size / 2}px Roboto`;
    context.fillText(text, size / 2, size / 2);
  }

  return canvas.toDataURL();
};

const nameImageBackgroundColorPool = [
  '#D93F0B',
  '#0E8A16',
  '#1D76DB',
  '#5319E7',
];

export const createImageFromName = (
  size: number,
  firstName: string,
  lastName: string,
): string => {
  const text = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  const pos =
    (text.charCodeAt(0) + text.charCodeAt(1)) %
    nameImageBackgroundColorPool.length;
  const color = nameImageBackgroundColorPool[pos];
  return createImageFromText(size, text, color);
};

export const formatStatus = (status: OrderStatus): string => {
  return status.split('_').map(capitalize).join(' ');
};

export const formatCoachStatus = (status: CoachStatus): string => {
  return status.split('_').map(capitalize).join(' ');
};

export const formatReviewStatus = (status: ReviewStatus): string => {
  return status.split('_').map(capitalize).join(' ');
};

/**
 * Format coupon discount details
 * @param type coupon type
 * @param pricingType order pricing type
 * @param discountValue discount value
 * @returns
 */
export const formatCouponDiscount = (
  type: CouponType,
  pricingType: Possible<CouponPricingType>,
  discountValue: number,
): string => {
  const pricingTypeLabel = pricingType
    ? COUPON_PRICING_TYPE_LABEL[pricingType]
    : ALL_SERVICES_COUPON_TYPE;

  switch (type) {
    case CouponType.FIXED_AMOUNT:
      return `${formatPrice(discountValue)} off ${pricingTypeLabel}`;
    case CouponType.PERCENTAGE_OFF:
      return `${discountValue}% off ${pricingTypeLabel}`;
    default:
      return assertUnreachable(type, CouponType);
  }
};

export const formatCoachingFlagAndStatus = (
  status: CoachingRelationshipStatus | CoachingRelationshipFlag,
): string => {
  return Object.values(CoachingRelationshipFlag).includes(
    status as CoachingRelationshipFlag,
  )
    ? formatCoachingFlag(status as CoachingRelationshipFlag)
    : formatCoachingStatus(status as CoachingRelationshipStatus);
};

export const formatCoachingStatus = (
  status: CoachingRelationshipStatus,
): string => {
  return COACHING_STATUS_LABELS[status];
};

export const formatCoachingFlag = (flag: CoachingRelationshipFlag): string => {
  return COACHING_FLAG_LABELS[flag];
};

export const formatGoal = (goal: GoalName): string => {
  return GOAL_NAME_LABELS[goal];
};

export const formatExperienceType = (
  experienceType: ExperienceType,
): string => {
  return EXPERIENCE_TYPE_LABELS[experienceType];
};

export const formatCareerGoalTarget = (target: CareerGoalTarget): string => {
  return CAREER_GOAL_TARGET_LABELS[target];
};

export const formatPreferredPronoun = (
  preferredPronoun: PreferredPronoun,
): string => {
  return PREFERRED_PRONOUN_LABELS[preferredPronoun];
};

export const formatList = (list: string[]): string => {
  if (list.length <= 1) return list[0] ?? '';
  const [lastItem] = list.splice(-1, 1);
  return `${list.join(', ')}${list.length > 1 ? ',' : ''} and ${lastItem}`;
};

export const formatSubscriptionInterval = (period: SubscriptionInterval) => {
  switch (period) {
    case SubscriptionInterval.ANNUAL:
      return 'year';
    case SubscriptionInterval.MONTHLY:
      return 'month';
    case SubscriptionInterval.ONE_TIME:
      return 'one time';
    case SubscriptionInterval.QUARTERLY:
      return 'quarter';
  }
};

export const formatSkuHours = (hours: number, period: SubscriptionInterval) => {
  return `${hours}h per ${formatSubscriptionInterval(period)}`;
};

export const formatLongNumber = (number: number) => {
  return number.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};
